
import { createContext, useContext, useState, useEffect } from "react";

//name context
export const AppContext = createContext();

export const AppContextProvider = (props) => {
  const [navState, setNavState] = useState("noVisible");
  const [lightModeState, setLightModeState] = useState(localStorage.getItem('lightMode')||"night");
  let [pricingItems, setPricingItems] = useState([]);

  useEffect(() => {
    // Realizar la solicitud asíncrona dentro de un efecto
    fetch("../php/pricing.php")
      .then((response) => response.json())
      .then((data) => {
        // Actualizar el estado con los datos obtenidos
        const updatedPricingItems = data.map((item) => {
          const itemsHaveArray = item.itemsHave.split(' | ');
          const itemsNoHaveArray = item.itemsDontHave.split(' | ');

          return {
            ...item,
            itemsHave: itemsHaveArray,
            itemsDontHave: itemsNoHaveArray,
          };
        });

        setPricingItems(updatedPricingItems);
      })
      .catch((err) => console.log(err));
  }, []); // La dependencia vacía asegura que este efecto solo se ejecute una vez al montar el componente

    //     pricingItems =[{tittle:"LANDING PAGE", description:"Pagina web de aterrisaje" ,
    // itemsHave:["adaptable a multibles dispositiovs","hasta 6 sesiones de navegacion","incluye hosting","garantia"], 
    // itemsDontHave:["carrito de compras","catalogo de productos"],price:900000}]
  return (
    <AppContext.Provider value={{ navState, setNavState, pricingItems,lightModeState,setLightModeState }}>
      {props.children}
    </AppContext.Provider>
  );
};