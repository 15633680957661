import { useContext, useEffect } from "react"
import { AppContext } from "../context/appcontext"
 
 const LightMode =()=>{
    const {lightModeState, setLightModeState} = useContext(AppContext)

   
    useEffect(() => {
        // Lógica que quieres ejecutar
        let  p = document.getElementById("pLightMode")
        if( lightModeState==="day"){
            p.classList.add("div--lightMode__p--animationDay")
            setTimeout(()=>{
                p.classList.remove("div--lightMode__p--animationDay")
            },1000)
        }
        else {
            p.classList.add("div--lightMode__p--animationNight")
            setTimeout(()=>{
                p.classList.remove("div--lightMode__p--animationNight")
            },1000)
        }
      
        return () => {
          // Lógica de limpieza (si es necesario)
        };
      }, [lightModeState]); // Array de dependencias
   

    function alternateLightMode(state){
        if (state=="night"){
            
            setLightModeState("day")
            localStorage.setItem("lightMode","day")
            
        }
        else{
            setLightModeState("night")
            localStorage.setItem("lightMode","night")
        }
        console.log(lightModeState)
    }
    return ( 
    <div className={`div--lightMode ${window.innerWidth < 900? "div--lightMode--mobile":""}`} onClick={()=> alternateLightMode(lightModeState)}>

            <p id ="pLightMode"className={`${lightModeState ==="night"? "p--lightmode--night":"p--lightmode--day"}`}>

            
            {lightModeState === "night" ? <i className="fa-solid fa-moon"></i> : <i className="fa-solid fa-sun"></i>}
            </p>

    </div>)

 }

 export default LightMode