import { useContext } from "react"
import { AppContext } from "../context/appcontext"
import AboutUsCard from "./AboutUsCard"
import Atropos from "atropos/react"


const AboutUsPage =()=>{
const{lightModeState} = useContext(AppContext)
return(
<div id="div--AboutUsPage" className={` div--AboutUsPage ${lightModeState=="day"?"div--AboutUs--day":""}`}>

    <h3>About Us</h3>
    <div className={`div--AboutUs ${window.innerWidth < 900?"div--AboutUs--mobile":""}`}>

        <div className={`div__AboutUs--image ${window.innerWidth < 900?"div--AboutUs--image--mobile":""}`}>
            <img src="./../imgs/team.png" alt="" />

        </div>
        <div  className={`div__AboutUs--message ${window.innerWidth < 900?"div--AboutUs--message--mobile":""}`}>

      <p>We are two   skilled developers and designers dedicated to turning your ideas into seamless, user-friendly, and visually stunning websites. From concept to code, we're here to transform your vision into reality. Let's build something extraordinary together!</p>
  
      
 
        </div>
    </div>
    <div className={`div--AboutUs--card ${window.innerWidth < 1200?"div--AboutUs--card--mobile":""}`}>

        <AboutUsCard github={<a><i className="fa-brands fa-github"></i> <b> @wilmerx5 </b></a>} phrase ="Try :)" src="../../imgs/profile2.png" description={(
  <>
    I am a seasoned developer with over four years of experience, specializing in a diverse range of technologies.<br />
    My expertise spans across Node.js, Express.js, React.js, MySQL, and MongoDB.
    <br/> With a passion for crafting innovative solutions, I thrive on transforming ideas into robust and scalable applications. My commitment to staying updated with the latest trends ensures that I bring cutting-edge solutions to every project. Let's collaborate and bring your digital vision to life!
  </>
)}> 

        </AboutUsCard>

        <AboutUsCard github = {<a><i className="fa-brands fa-github"></i> <b>@sofi20</b> </a>}phrase ="Believe in Yourself" src="../../imgs/profile1.png" description="I am a seasoned developer with over four years of experience, specializing in a diverse range of technologies. My expertise spans across Node.js, Express.js, React.js, MySQL, and MongoDB. With a passion for crafting innovative solutions, I thrive on transforming ideas into robust and scalable applications. My commitment to staying updated with the latest trends ensures that I bring cutting-edge solutions to every project. Let's collaborate and bring your digital vision to life!"> 

        </AboutUsCard>

    </div>
    <h3 className="h3--technologiesused">Technologies Used</h3>
    <div className={`container--technologiesUsed ${window.innerWidth < 1200?"container--technologiesUsed--mobile":""}`}>
    <Atropos className="my-atropos"   shadow={false}>
      <img src="../../imgs/jsLogo.png" data-atropos-offset="-5" />
      </Atropos>
      <Atropos className="my-atropos"   shadow={false}>
      <img src="../../imgs/reactLogo.png" data-atropos-offset="-5" />
      </Atropos>
      <Atropos className="my-atropos"   shadow={false}>
      <img src="../../imgs/phpLogo.png" data-atropos-offset="-5" />
      </Atropos>
      <Atropos className="my-atropos"   shadow={false}>
      <img src="../../imgs/wpLogo.png" data-atropos-offset="-5" />
      </Atropos>
      <Atropos className="my-atropos"   shadow={false}>
      <img src="../../imgs/mysqlLogo.png" data-atropos-offset="-5" />
      </Atropos>
      <Atropos className="my-atropos"   shadow={false}>
      <img src="../../imgs/mongoDbLogo.png" data-atropos-offset="-5" />
      </Atropos>
      <Atropos className="my-atropos"   shadow={false}>
      <img src="../../imgs/nodeLogo.png" data-atropos-offset="-5" />
      </Atropos>
      <Atropos className="my-atropos"   shadow={false}>
      <h3>...</h3>
      </Atropos>
    </div>

</div>

)

}
export default AboutUsPage