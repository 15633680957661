import React, { useContext } from "react"
import '../css/index.css';
import Swal  from 'sweetalert2'
import { AppContext } from "../context/appcontext";

const Logo = (props)=>{
    const {lightModeState} = useContext(AppContext)
    return(<img onClick={()=>{
            
            // Swal.fire({
            //     title: 'Error!',
            //     text: 'Do you want to continue',
            //     icon: 'error',
            //     confirmButtonText: 'Cool'
            // })
    }} className={`logo ${props.className} ${lightModeState=="day"? 'logo--day' :""}`} src="../../imgs/logo.png" alt="logo"/>)
}

export default Logo