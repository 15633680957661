import {React, useContext} from "react";
import { AppContext } from "../context/appcontext";
import ImgWelcomePage from "./imgWelcomePage";
import Button from "./Buttons";
const WelcomePage = ()=>{
    const {lightModeState} = useContext(AppContext)
return(<div className={`container__div--welcomePage ${window.innerWidth < 900?"container__div--welcomePage--mobile":""} ${lightModeState =="day" ?"container__div--welcomePage--day":""}`}>
    <div className="div--welcomePage">
        <div className={`div--welcomePage__message ${lightModeState =="day" ?"div--welcomePage__message--day":""} `}>
            <p>Take your ideas</p>
            <h2>To the top with us!</h2>
            <Button className={`${window.innerWidth < 900?"Button--welcomePage--mobile":""} 
            ${lightModeState =="day" ?"Button--welcomePage--day":""}
            `} ><a>Consulta aqui <i className="fa-solid fa-arrow-right div__i--arrow"></i></a></Button>
        </div>
        <div className="div--welcomePage__img">
            <ImgWelcomePage/>
        </div>

    </div>
</div>)

}

export default WelcomePage