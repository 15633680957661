import {React, useContext} from "react";
import { AppContext } from "../context/appcontext";
import Button from "./Buttons";
import UlPricing from "./ulPricing";
const PricingPage =(props)=>{ 
    const {pricingItems,lightModeState} = useContext(AppContext)
    console.log(pricingItems)
    return(
        <>
        <h3 className={`PricingPage__h3 ${window.innerWidth < 1220?"PricingPage__h3--mobile":""} ${ lightModeState == "day" ?"PricingPage__h3--day":""}`}>PRICING</h3>
         <div className={` div--pricingPage ${ lightModeState == "day" ?"div--pricingPage--day":""}`}  id="div--pricingPage">


{pricingItems.map((e)=>
<UlPricing className={`${window.innerWidth < 1220?"ul--pricing--mobile":""} ${ lightModeState == "day" ?"ul--pricing--day":""}`} key={e.tittle} tittle={e.tittle} price={e.price} description ={e.description}> 
{e.itemsHave.map(x=><li key={x}><i className="fa-solid fa-check"></i>   {x}</li>)}
{e.itemsDontHave[0] !== "noapply"? e.itemsDontHave.map(x=><li key={x}><i className="fa-solid fa-xmark"></i>  {x}</li>):""}
<Button 
className={` Button--pricingPage ${window.innerWidth < 900?"Button--pricingPage--mobile":""} ${lightModeState == "day" ?"Button--pricingPage--day":""}`} >
Know More <i className="fa-solid fa-arrow-right div__i--arrow arrow--prcing"></i>
</Button>
</UlPricing>)}
</div>
        </>
   )
}

export default PricingPage