
import '../css/App.css';
import '../css/dayMode.css';
import 'atropos/css'
import Atropos from 'atropos/react'
import { useContext } from 'react';
import Nav from './Nav';
import WelcomePage from './welcomePage';
import PricingPage from './pricingpage';
import AboutUsPage from './AboutUsPage';
import Footer from './footer';
import { AppContext } from '../context/appcontext';



function App() {
  const {navState,setNavState,lightModeState} = useContext(AppContext)
  return (
    <div className="App">

      <header className={`App-header ${window.innerWidth<1200 ?"App-header--mobile":""} ${lightModeState=="day" ?"App-header--day":""}`}>
      {window.innerWidth<1200?<i onClick={()=>setNavState("Visible")} id="nav__toogle--mobile"><i className="fa-solid fa-bars"></i></i>:''}
        <Nav></Nav>

      </header>
      <div className={`App-body ${window.innerWidth>1200 ?"App__body__desktop":""}`} >

      <WelcomePage></WelcomePage>
      <AboutUsPage></AboutUsPage>
      <PricingPage ></PricingPage>
        
      </div>
      <div className='App__footer'>
        <Footer></Footer>
      </div>
    </div>
  );
}

export default App;
