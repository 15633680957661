
import SocialMedia from "./socialMedia"
import { useContext } from "react"
import { AppContext } from "../context/appcontext"
const Footer =(props)=>{
    const {lightModeState} = useContext(AppContext)
    return(  
    <footer id="footer" className={` ${window.innerWidth < 900?"footer--mobile":""} ${lightModeState =="day"?"footer--day":""}`}>
    <h3>Conctat Us</h3>
    <p>Leave us your requirements</p>    
    <div className={`form--contactMethods ${window.innerWidth < 900?"form--contactMethods--mobile":""}  ${lightModeState =="day"?"form--contactMethods--day":""}`}>
        <div className={ window.innerWidth < 1220?"div--viaContact--mobile":""} >
                <p >Use the next ways to contact or fill the form.</p>

                <p className="p--viaContact"> via email</p>
                <a>asesorias@softweb.com</a>
                <p className="p--viaContact"> phone </p>
                <a> +57 3107802601</a>
        </div>
        <form id ="form--contactUs" className={` ${window.innerWidth < 900?"form--contactUs--mobile":""} ${lightModeState =="day"?"form--contactUs--day":""}`}method="post">
            <input type ="text" name ="name" placeholder="name"/>
            <input  type ="text" name ="email" placeholder="email"/>
            <input  type ="text" name ="phone" placeholder="phone"/>
            <textarea  name ="messagge" placeholder="message"/>
            <input  type ="submit" name ="submit" value="send message"/>

        </form>

    </div>
    <SocialMedia></SocialMedia>
    </footer>
    )
}

export default Footer