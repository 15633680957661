


const AboutUsCard =(props)=>{
return(
    <ul className="AboutUs--Card">

        <img src={props.src} alt="" />
        <li>{props.github}</li>
        <li>{props.description}</li>
        <li><b>{props.phrase}</b></li>
    </ul>
)

}

export default AboutUsCard