
const SocialMedia =()=>{
return (
    <p className={`container--socialMedia ${window.innerWidth < 900?"container--socialMedia--mobile":""}`}>
        <a><i className="fa-brands fa-facebook"></i> </a>
        <a> <i className="fa-brands fa-linkedin"></i></a>
        <a> <i className="fa-brands fa-square-instagram"></i></a>
        <a> <i className="fa-brands fa-square-whatsapp"></i></a>


       
        
        
    </p>
)

}

export default SocialMedia