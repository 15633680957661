

const UlPricing =(props)=>{

    return (<ul className={`ul--pricing ${props.className}`}>
        <h3> {props.tittle} </h3>
        <h3>COP ${props.price} </h3>
        <p>{props.description}</p>

        {props.children}
    </ul>)
}
export default UlPricing