import {React, useContext,useRef,useEffect } from "react";
import { AppContext } from "../context/appcontext";
import Logo from "./logo";
import LightMode from "./LightMode";
const Nav = () => {
    // manejar estado del nav visible y no visible
    const {navState, setNavState,lightModeState,}=  useContext(AppContext)
    //va a hacer referencia a todo el nav, inicia en nulo
    const wrapperRef = useRef(null);
    const handleClickOutside = (event) => {
        if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
         
        setNavState("NoVisible")
        }
      };
      useEffect(() => {
        // Agrega el evento global para detectar clics fuera del elemento
        document.addEventListener("mousedown", handleClickOutside);
    
        // Limpia el evento al desmontar el componente
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [handleClickOutside]);
  
    return window.innerWidth > 1200 ? (
        <nav className={`nav ${lightModeState=="day"? 'nav--day' :""} `}>
            <Logo  />
            <LightMode></LightMode>
            <ul className="nav__ul">
                <li><a href="#div--AboutUsPage">Meet Us</a> </li>
                <li><a href="#div--pricingPage">Our Products</a></li>
                <li><a href="#footer">Contact Us</a></li>
            </ul>
        </nav>
    ) : (
       
            <>
            
            <Logo  className="logo--mobile"> </Logo>
            <LightMode></LightMode>
        <nav  ref={wrapperRef} className={ `nav--mobile ${navState==="Visible"? 'nav--mobile--visible' :""} ${lightModeState=="day"? 'nav--mobile--day' :""} `}>
            
            <ul id ="nav__ul--mobile" className={`${lightModeState=="day"? 'nav__ul--day' :""}`}>
                <li><a onClick={()=> setNavState("NoVisible")} href="#div--AboutUsPage">Meet Us</a> </li>
                <li><a onClick={()=> setNavState("NoVisible")} href="#div--pricingPage">Our Products</a></li>
                <li><a onClick={()=> setNavState("NoVisible")} href="#footer">Contact Us</a></li>
                
                
            </ul>
        </nav>
        </>
    );
};
export default Nav